import React, { useEffect, useRef, useState } from 'react';
import './index.sass';
import Slider from '../Slider';
import { SoundtrackItem, SoundtrackPlaylist } from '../../models/soundModels';
import { calculateTime, getMetaData, getFilePath, numberToFloat } from './tools'
import { ControlsNext, ControlsPlay, ControlsPrevious } from './controls';


interface IAudioPlayer {
  initialTrack?: number,
  playlist: SoundtrackPlaylist
  onTrack?: (track:SoundtrackItem|undefined) => void
  onUpdate?: (pct:number) => void
}


const AudioPlayer: React.FC<IAudioPlayer> = (props) => {

  const selfRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  
  const shortCRef = useRef<HTMLDivElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  
  const [currentTrack, setCurrentTrack] = useState(props.initialTrack || 0);

  const [duration, setDuration] = useState(0.0);
  const [current, setCurrent] = useState(0.0);
  const [preview, setPreview] = useState(0.0);
  const [bufferedAmount, setBufferedAmount] = useState(0);

  const hasAudio = (callback:(audio:HTMLAudioElement)=>void) => {
    if (audioRef && audioRef.current) {
      callback(audioRef.current);      
    }
  }

  // Update play info
  const handleLoaded = () => { 
    hasAudio((audio) => {
      setDuration(audio.duration);         
      try {
        setBufferedAmount(audio.buffered.end(audio.buffered.length - 1));
      } catch {}
    });
  }

  const handleTimeUpdate = () => {  
    hasAudio((audio) => {
      setCurrent(audio.currentTime/duration);  
      try {
        navigator.mediaSession.setPositionState({
          duration: audio.duration,
          playbackRate: audio.playbackRate,
          position: audio.currentTime
        });
      } catch {}   
      if (props.onUpdate) props.onUpdate(current);
      try {
        setBufferedAmount(audio.buffered.end(audio.buffered.length - 1));
      } catch {}   
    });
  }

  const handleChangeValue = (pct:number) => {
    hasAudio((audio) => {
      setCurrent(pct);
      audio.currentTime = pct * duration;
    });
  }

  // Player Controls
  const PlayPause = () => {   
    hasAudio((audio) => {      
      if (!audio.src) {
        try {
          setTrack(0);
        } catch {}
      }      
      if (!audio.paused) {
        try {
          audio.pause();
        } catch {}
      } else {
        try {
          audio.play();
        } catch {}
      }
    });
  }

  const setTrack = (track:number = currentTrack, isPlaying:boolean = false) => {
    hasAudio((audio) => {
      if (track !== currentTrack || !audio.src) {
        audio.src = getFilePath(props.playlist[track]);                
        navigator.mediaSession.metadata = getMetaData(props.playlist[track]);
        setCurrentTrack(track);
        if (props.onTrack) props.onTrack(props.playlist[track]);
        if (isPlaying) PlayPause();
      }            
    });
  }

  const handleTracks = (mod:number, forcePlay:boolean = false) => {
    hasAudio((audio) => {
      const index = Math.abs((currentTrack + mod + props.playlist.length) % props.playlist.length);    
      if (mod < 0 && audio.currentTime >= 2) {
        audio.currentTime = 0;
        setCurrent(0)
      } else {
        if (props.onTrack) props.onTrack(props.playlist[index]);
        setTrack(index, (!audio.paused || forcePlay)); 
      }    
    });
  }

  const open = () => {
    if (opened) {
      setHeight(0);
      setOpened(false);
    } else {
      setHeight(heightRef.current);
      setOpened(true);
    }
  }

  const heightRef = useRef<number>(0)
  const [height, setHeight] = useState(0);
  const [opened, setOpened] = useState(false);
  const [plst, setPlst] = useState<SoundtrackPlaylist>([])
  
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          heightRef.current = entry.contentRect.height;
        }
      }
    });

    if (listRef.current) resizeObserver.observe(listRef.current);

    // Tracking Events
    navigator.mediaSession.setActionHandler('previoustrack', () => { handleTracks(-1) });
    navigator.mediaSession.setActionHandler('nexttrack', () => { handleTracks(+1) });
    

    const isNewPlaylist = JSON.stringify(plst) !== JSON.stringify(props.playlist);
    if (isNewPlaylist) {
      setPlst(props.playlist);  
      hasAudio((audio) => {       
        audio.src = getFilePath(props.playlist[0]);                
        navigator.mediaSession.metadata = getMetaData(props.playlist[0]);
        audio.currentTime = 0;
        setCurrent(0)
        setCurrentTrack(0);
      }); 
    }
    
    return () => {
      if (listRef.current) resizeObserver.unobserve(listRef.current);
    }
    
  }, [currentTrack, plst, props.playlist])

  return ( 
    <div className="AudioPlayer" ref={selfRef}>
      {plst.length && 
        <div className='AudioPlayer__Top'>
          <div className='AudioPlayer__Cover'>
            <img ref={imgRef} src={`covers/${plst[currentTrack].tag}.jpg`}/>
          </div>
          <div className='AudioPlayer__Title' ref={titleRef}>
            <div className='AudioPlayer__Name ellipsis'>{plst[currentTrack].name}</div>
            <div className='AudioPlayer__Description ellipsis'>{plst[currentTrack].album}</div>
          </div>
          {/* <div onClick={()=>{ open() }}>sdsd</div> */}
          <div className='AudioPlayer__ShortControls' ref={shortCRef}>
            <ControlsPlay paused={audioRef.current?.paused} onPlayPause={PlayPause}/>
            <ControlsNext onTrack={handleTracks}/>
          </div>
        </div>
      }
      <div className='AudioPlayer__Time'>
        <span>{calculateTime(current * duration)}</span>
        <Slider
          cache={bufferedAmount/duration} 
          previewValue={calculateTime(preview * duration)} 
          value={current}
          onPreviewValue={(val:number) => { setPreview(val) }} 
          onChangeValue={handleChangeValue}/>
        <span>{calculateTime(duration)}</span>
      </div>
      <div className='AudioPlayer__Controls'>
        <ControlsPrevious onTrack={handleTracks}/>
        <ControlsPlay paused={audioRef.current?.paused} onPlayPause={PlayPause}/>
        <ControlsNext onTrack={handleTracks}/>
      </div>
      <div className='AudioPlayer__List transition' style={{ height }}>
        <div ref={listRef}>
          { plst.map( (t) => 
            <div key={`list_${t.name}`}>{t.name}</div>
          )}
        </div>
      </div>
      <audio ref={audioRef}
        preload='metadata'
        autoPlay
        onLoadedMetadata={handleLoaded}
        onTimeUpdate={handleTimeUpdate} 
        onEnded={()=>{handleTracks(+1, true)}}
        ></audio>
    </div>
  );
}

export default AudioPlayer;