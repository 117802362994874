import React, { useState } from 'react';
import './index.sass';
import { release, SoundtrackItem, SoundtrackPlaylist } from '../../models/soundModels';


interface OtherRel {
  releases: Array<release>,
  t: SoundtrackItem|undefined,
  onTracks?: (tracks:SoundtrackPlaylist) => void,
  onOpen?: (r:release) => void,
}


function OtherReleases(props:OtherRel) {
  
  const playAlbum = (r:release|undefined) => {
    const trcks:SoundtrackPlaylist = [];
    r?.tracks.forEach((t) => {
      trcks.push({
        name: t.name,
        album: r.name,
        tag: r.name,
        lyrics: t.lyrics,
      });
    });
    emitTracks(trcks);
  }

  const emitTracks = (tracks:SoundtrackPlaylist) => {
    if (props.onTracks) props.onTracks(tracks);
  }

  const emitOpen = (r:release) => {
    if (props.onOpen) props.onOpen(r);
  }

  return (
    <div className="OtherReleases">
      <div className='releases'>
        { props.releases.map((r:release, i:number) => (
          <div key={i} className={`release ${props.t?.name === r.name ? 'playing' : ''}`}> 
            <img src={r.cover} alt="" className='transition' onClick={()=>emitOpen(r)}/>
            <div className='name transition'>{r.name}</div>
            <div className='controls transition'>
              <svg className='play transition' viewBox="0 0 32 32" onClick={()=> { playAlbum(r) }}>
                <polygon points="10,8 10,15 10,24 13,22.2 17,20 24,16 24,16 17,12 13,9.5 10,8" fill="white"/>
              </svg>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OtherReleases;
