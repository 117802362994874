import { SoundtrackItem } from '../../models/soundModels';
import { snakeCase } from 'lodash';

const calculateTime: (secs:number) => string = (secs = 0) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  }

const getMetaData = (item:SoundtrackItem) => {
    return new MediaMetadata({
        title: item.name,
        artist: 'Ossamenta',
        album: item.album,
        artwork:[
          { src: `covers/${item.tag}.jpg`, sizes: "96x96", type: "image/jpg" },
          { src: `covers/${item.tag}.jpg`, sizes: "128x128", type: "image/jpg" },
          { src: `covers/${item.tag}.jpg`, sizes: "256x256", type: "image/jpg" },
          { src: `covers/${item.tag}.jpg`, sizes: "384x384", type: "image/jpg" },
          { src: `covers/${item.tag}.jpg`, sizes: "512x512", type: "image/jpg" },
        ]
      });
}

const getFilePath = (item:SoundtrackItem) => {
    return `audio/mp3/${item.name === item.album ? item.tag : item.name}.mp3`;
}

const numberToFloat = (number:number) => {
    return parseFloat((number).toFixed(2));   
}

export {
    calculateTime,
    getMetaData,
    getFilePath,
    numberToFloat
}