import React, { useEffect, useRef } from 'react';
import './index.sass';
import anime from 'animejs';

const ControlsPlay: React.FC<any> = (props) => {

  const animeRef = useRef<SVGPolygonElement>(null);  
  const morph = (rev:boolean) => {
    const pts = rev 
      ? '10,8 10,15 10,24 13,22.2 17,20 24,16 24,16 17,12 13,9.5 10,8' 
      : '16,10 12,10 12,22 16,22 16,10 18,22 22,22 22,10 18,10 18,22';

    anime({
      targets: animeRef.current,
      points: pts,
      easing: 'easeInOutSine',
      duration: 100,
    });
  }

  useEffect(() => {
    morph(props.paused);
  }, [props.paused, morph]);

  const handlePlayPause = () => {
    if (props.onPlayPause) props.onPlayPause();
  }

  return ( 
    <svg viewBox="0 0 32 32" onClick={handlePlayPause}>
      <polygon ref={animeRef} points="10,8 10,15 10,24 13,22.2 17,20 24,16 24,16 17,12 13,9.5 10,8" fill="white"/>
    </svg>
  );
}

const ControlsNext: React.FC<any> = (props) => {
  
  const handleTrack = (mod:number) => {
    if (props.onTrack) props.onTrack(mod);
  }

  return ( 
    <svg viewBox="0 0 32 32" onClick={()=> { handleTrack(+1) }}>
      <polygon points="10,10 10,22 20,16 20,22 24,22 24,10 20,10, 20,16 10,10" fill="white"/>
    </svg>
  );
}

const ControlsPrevious: React.FC<any> = (props) => {
  
  const handleTrack = (mod:number) => {
    if (props.onTrack) props.onTrack(mod);
  }

  return ( 
    <svg viewBox="0 0 32 32" onClick={()=> { handleTrack(-1) }}>
      <polygon points="10,10 10,22 14,22 14,16 24,22 24,10 14,16 14,10 10,10" fill="white"/>
    </svg>
  );
}

export { ControlsPlay, ControlsNext, ControlsPrevious };