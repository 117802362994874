import React, { useState } from 'react';

import './App.sass';
import Footer from './components/footer';
import Header from './components/header';
import OtherReleases from './components/other-releases';
import releases from './data/releases';
import Wait from './components/new';
import AudioPlayer from './components/AudioPlayer';
import { release, SoundtrackItem, SoundtrackPlaylist } from './models/soundModels';
import { cloneDeep } from 'lodash';
import MoreInfo from './components/MoreInfo';

function App() {

  const [playlist, setPlaylist] = useState<SoundtrackPlaylist>([]);
  const [track, setTrack] = useState<SoundtrackItem|undefined>();
  const [minWidth, setMinWidth] = useState(0);
  const [opened, setOpened] = useState(false);
  const [currentInfo, setCurrentInfo] = useState<release|undefined>()


  const addTracks = (tracks:SoundtrackPlaylist) => {    
    // let nPlaylist:SoundtrackPlaylist = cloneDeep(playlist);
    // nPlaylist = [...tracks, ...nPlaylist];
    // setPlaylist(nPlaylist);    
    setPlaylist(tracks);
  }  

  const handleOpen = (r:release|null) => {
    if (r) {
      setCurrentInfo(r);
      setMinWidth(Math.min(window.innerWidth, 400));
      setOpened(true);
    } else {
      setMinWidth(0);
      setOpened(false);
    }
  }
  
  return (
    <div className="App">
      <div className='App__Main scrollable'>
        <Header/> 
        <Wait/>
        <OtherReleases t={track} releases={releases} onOpen={handleOpen} onTracks={addTracks}/>
        <Footer/>
        {
          !!playlist.length && 
            <AudioPlayer
              playlist={playlist}
              onTrack={setTrack} 
              onUpdate={(pct)=>{  }}/>
        }
      </div>
      <div className='App__More transition scrollable'style={{ minWidth }}>
        <MoreInfo current={currentInfo} onClose={handleOpen} onTracks={addTracks}/>
      </div>
    </div>
  );
}

export default App;
