import React, { useState } from 'react';
import './index.sass';
import { release, SoundtrackItem, SoundtrackPlaylist } from '../../models/soundModels';


interface MoreInfoProps {
  current?: release,
  onTracks?: (tracks:SoundtrackPlaylist) => void,
  onClose?: (n:null) => void
}

function MoreInfo(props:MoreInfoProps) {


  const emitClose = (n:null) => {
    if (props.onClose) props.onClose(null);
  }

  const playAlbum = (r:release|undefined) => {
    const trcks:SoundtrackPlaylist = [];
    r?.tracks.forEach((t) => {
      trcks.push({
        name: t.name,
        album: r.name,
        tag: r.name,
        lyrics: t.lyrics,
    });
    emitTracks(trcks);
    });
  }

  const emitTracks = (tracks:SoundtrackPlaylist) => {
    if (props.onTracks) props.onTracks(tracks);
  }

  return (
    <div className='MoreInfo'>
      <div className='releaseViewContent'>
        <button onClick={() => emitClose(null) }>FECHAR</button>
        <div className='topper'>
          <img src={props.current?.cover} alt="" className='transition'/>
          <div>{props.current?.name}</div>
          {/* <div onClick={ () => { playAlbum(props.current) } }>P</div> */}
        </div>
        <div className='wrapper scrollable'>
          {
            props.current?.tracks.map( (t, i) => 
              [
                <div className='title' key={`title${i}`}>{t.name}</div>,
                <div key={`lyric${i}`}>{t.lyrics}</div>
              ]
            )
          }
        </div>
      </div>
    </div>
  );
}

export default MoreInfo;
